<!--授权，从这边跳转到后端，然后后端带着参数跳转到这个页面，后续每一个axios都需要设置cookie-->
<template>
    <div></div>
</template>

<script>
  import { globalConfig } from '../../utils/mixin'
  import { getCookie } from '../../utils/cookie'

  export default {
    name: 'Auth',
    mixins: [globalConfig],
    mounted () {
      if (getCookie('userAuth') == 1) {
        // 已经授权了，在router 里面已经前置判断 next()
      } else {
        // 在建行app则不能按照auth2.0的方式去进行，这个时候后端实际上已经授权完成，直接去notify组件即可。这个方式是没考虑跨域的情况。
        // if (getCookie('isCcbApp') == 1) {
        //   this.$router.push('/authNotify')
        //   return
        // }
        // 前往授权不代理url。去获取真实的session
        const url = process.env.VUE_APP_AUTH_URL
        window.location.replace(url)
      }
    }
  }
</script>

<style scoped>

</style>
